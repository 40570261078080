import {
    CompositeDecorator,
    ContentState,
    convertFromHTML,
    Editor,
    EditorState,
    RichUtils
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import 'draft-js/dist/Draft.css';
import { FieldState } from 'formstate';
import * as React from 'react';
import styled from 'styled-components';
import { Button } from '../button';
import { Label } from '../label';
import { Layout } from '../layout';

export interface TextareaProps<V = any> {
    value: V;
    height?: number;
    placeholder?: string;
    label?: string;
    autoFocus?: boolean;
    disabled?: boolean;
    wysiwyg?: boolean;
    'data-role'?: string;
    onChange?(value: V): void;
}

const Input = styled.textarea<TextareaProps>`
    padding: 9px;
    width: 100%;
    height: ${(props) => props.height || 120}px;
    font-family: 'Ubuntu', sans-serif;
    font-size: 14px;
    border: 1px solid #ccc;
    resize: none;

    &::placeholder {
        color: #aaa;
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }

    &:disabled {
        background-color: #f9f9f9;
    }
`;

const EditorContainer = styled.div<{ height?: number }>`
    padding: 5px;
    border: 1px solid #ccc;
    height: ${(props) => props.height || 120}px;
    overflow-y: auto;
    font-family: Helvetica, Arial, sans-serif;
    box-sizing: content-box;

    [data-block='true'] {
        margin-bottom: 12px;
    }
`;

const EditorButton = styled(Button)`
    width: 30px;
    height: 30px;
`;

function findLinkEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return (
            entityKey !== null &&
            contentState.getEntity(entityKey).getType() === 'LINK'
        );
    }, callback);
}

const Link = (props) => (
    <a href={props.contentState.getEntity(props.entityKey).getData()}>
        {props.children}
    </a>
);

export function Textarea<V = string>(props: TextareaProps<V>) {
    const { placeholder, autoFocus, label, disabled, height, wysiwyg, value } =
        props;
    const [oldValue, setOldValue] = React.useState<V | undefined>(undefined);
    const [editorState, setEditorState] = React.useState<
        EditorState | undefined
    >(() => {
        if (wysiwyg) {
            if (value) {
                const decorator = new CompositeDecorator([
                    {
                        strategy: findLinkEntities,
                        component: Link
                    }
                ]);

                const blocksFromHTML = convertFromHTML(String(value));
                const content = ContentState.createFromBlockArray(
                    blocksFromHTML.contentBlocks,
                    blocksFromHTML.entityMap
                );

                return EditorState.createWithContent(content, decorator);
            } else {
                return EditorState.createEmpty();
            }
        }

        return undefined;
    });

    React.useEffect(() => {
        if (wysiwyg && !oldValue && value) {
            const decorator = new CompositeDecorator([
                {
                    strategy: findLinkEntities,
                    component: Link
                }
            ]);

            const blocksFromHTML = convertFromHTML(String(value));
            const content = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );

            setEditorState(EditorState.createWithContent(content, decorator));
        }
    }, [wysiwyg, value, oldValue]);

    function getValue() {
        const value =
            props.value instanceof FieldState ? props.value.value : props.value;

        return value;
    }

    function onChange(e: any): void {
        const { value } = e.currentTarget;

        if (props.value instanceof FieldState) {
            props.value.onChange(value);
        }

        props.onChange?.(value);

        setOldValue(value);
    }

    function onEditorChange(editorState: EditorState): void {
        setEditorState(editorState);

        const html = stateToHTML(editorState.getCurrentContent()) as V;

        props.onChange?.(html);
        setOldValue(html);
    }

    function onEditorBold(): void {
        if (editorState) {
            onEditorChange(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
        }
    }

    function onEditorItalic(): void {
        if (editorState) {
            onEditorChange(RichUtils.toggleInlineStyle(editorState, 'ITALIC'));
        }
    }

    function onEditorUnderline(): void {
        if (editorState) {
            onEditorChange(
                RichUtils.toggleInlineStyle(editorState, 'UNDERLINE')
            );
        }
    }

    if (wysiwyg) {
        return (
            <Layout stack between="8">
                <Layout between="8">
                    <EditorButton onClick={() => onEditorBold()}>
                        <b>B</b>
                    </EditorButton>
                    <EditorButton onClick={() => onEditorItalic()}>
                        <i>I</i>
                    </EditorButton>
                    <EditorButton onClick={() => onEditorUnderline()}>
                        <u>U</u>
                    </EditorButton>
                </Layout>
                {editorState && (
                    <EditorContainer height={props.height}>
                        <Editor
                            spellCheck={false}
                            placeholder={props.placeholder}
                            editorState={editorState}
                            onChange={onEditorChange}
                        />
                    </EditorContainer>
                )}
            </Layout>
        );
    }

    if (label) {
        const { label, ...rest } = props;

        return (
            <Label text={label}>
                <Textarea {...rest} />
            </Label>
        );
    }

    return (
        <Input
            placeholder={placeholder}
            autoFocus={autoFocus}
            disabled={disabled}
            spellCheck={false}
            onChange={onChange}
            value={getValue()}
            data-role={props['data-role']}
            height={height}
        />
    );
}
